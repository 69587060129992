var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductCatalogMolecule', _vm._l(_vm.products, function (product) {
    return _c('ProductCatalogItemMolecule', {
      key: product.id,
      attrs: {
        "product": product
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
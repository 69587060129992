<template>
  <ProductCatalogItemMolecule>
    <ProductIconMolecule class="icon" :upload="upload" />
    <div class="name">
      <router-link
        :to="{
          name: 'ProductDetails',
          params: { id: product.id },
        }"
      >
        {{ product.name }}
      </router-link>
    </div>
    <ProductActionsAtom class="actions" :product="product" />
    <div class="short-description">
      <VueMarkdown v-if="product.shortDescription" class="markdown" :source="product.shortDescription" />
    </div>
    <div class="learn-more">
      <router-link
        :to="{
          name: 'ProductDetails',
          params: { id: product.id },
        }"
      >
        {{ $t('actions.showMore') }}
      </router-link>
    </div>
  </ProductCatalogItemMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import VueMarkdown from 'vue-markdown'

import ProductIconMolecule from './ProductIconMolecule'
import ProductActionsAtom from './ProductActionsAtom'

const ProductCatalogItemMolecule = styled('div')`
  padding: 1rem;
  display: grid;
  grid-template-columns: 4rem 1fr min-content;
  grid-template-rows: 4rem min-content min-content;
  grid-gap: 0.5rem 1rem;
  grid-template-areas:
    'icon name actions'
    'icon short-description short-description'
    'icon learn-more learn-more';
  background: transparent;
  background: ${({ theme }) => theme.colors.solidBG};
  border: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.darkestGrey : 'transparent')};
  box-shadow: ${({ theme }) => theme.colors.widgetShadowEnforced};
  border-radius: 0.5rem;
  overflow: hidden;

  > .icon {
    grid-area: icon;
    justify-self: center;
  }

  > .actions {
    grid-area: actions;
    align-self: center;
    height: 3rem;
  }

  > .name {
    grid-area: name;
    align-self: center;
    font-size: 1.1rem;
    font-weight: bold;
  }

  > .short-description {
    grid-area: short-description;
    > .markdown {
      > h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  > .learn-more {
    grid-area: learn-more;
    padding-top: 1rem;
    justify-self: end;
  }
`

export default {
  inject: ['theme'],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueMarkdown,
    ProductCatalogItemMolecule,
    ProductIconMolecule,
    ProductActionsAtom,
  },
  computed: {
    upload() {
      if (this.product?.hasDarkIconOnly) {
        return this.product.iconDark
      }
      return this.theme.isDark ? this.product.iconDark : this.product.iconLight
    },
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductCatalogPage', [_c('menu', _vm._l(_vm.productTypes, function (productType) {
    return _c('router-link', {
      key: productType.id,
      class: {
        'is-forced-active': productType.isForcedActive
      },
      attrs: {
        "to": productType.to
      }
    }, [_vm._v(" " + _vm._s(productType.label) + " ")]);
  }), 1), _c('section', [_c('ProductCatalogOrganism', {
    attrs: {
      "search": _vm.search,
      "type": _vm.selectedProductType
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ProductCatalogMolecule>
    <ProductCatalogItemMolecule v-for="product in products" :key="product.id" :product="product" />
  </ProductCatalogMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import ProductCatalogItemMolecule from './ProductCatalogItemMolecule'

const ProductCatalogMolecule = styled('div')`
  display: grid;
  grid-gap: 1rem;
`

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  components: {
    ProductCatalogMolecule,
    ProductCatalogItemMolecule,
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductCatalogItemMolecule', [_c('ProductIconMolecule', {
    staticClass: "icon",
    attrs: {
      "upload": _vm.upload
    }
  }), _c('div', {
    staticClass: "name"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'ProductDetails',
        params: {
          id: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")])], 1), _c('ProductActionsAtom', {
    staticClass: "actions",
    attrs: {
      "product": _vm.product
    }
  }), _c('div', {
    staticClass: "short-description"
  }, [_vm.product.shortDescription ? _c('VueMarkdown', {
    staticClass: "markdown",
    attrs: {
      "source": _vm.product.shortDescription
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "learn-more"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'ProductDetails',
        params: {
          id: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('actions.showMore')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
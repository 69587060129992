<template>
  <ApolloQuery :query="PRODUCTS_QUERY" :variables="variables">
    <template v-slot="{ result: { loading, error, data } }">
      <TalpaLoaderWrapper :transparent="true" v-if="loading" />
      <ErrorAtom v-else-if="error" :error="error" />
      <ProductCatalogMolecule v-else-if="data" :products="data.products" />
    </template>
  </ApolloQuery>
</template>

<script>
import { TalpaLoaderWrapper } from '@common/components'

import PRODUCTS_QUERY from '#/graphql/marketplace/productsQuery.gql'
import ErrorAtom from '@/components/Atomic/Atoms/ErrorAtom.vue'
import ProductCatalogMolecule from './ProductCatalogMolecule'

export default {
  inject: ['uiSettings'],
  props: {
    search: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ProductCatalogMolecule,
  },
  data() {
    return {
      products: [],
      PRODUCTS_QUERY,
    }
  },
  computed: {
    variables() {
      const where = {}
      const language = this.uiSettings?.language ?? 'EN'
      if (this.search.length > 0) {
        where.name = {
          contains: this.search,
          mode: 'insensitive',
        }
      }
      if (this.type !== undefined && this.type !== 'all') {
        where.type = {
          equals: this.type.toUpperCase(),
        }
      }
      return { where, language }
    },
  },
}
</script>

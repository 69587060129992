<template>
  <ProductCatalogPage>
    <menu>
      <router-link
        v-for="productType in productTypes"
        :key="productType.id"
        :to="productType.to"
        :class="{ 'is-forced-active': productType.isForcedActive }"
      >
        {{ productType.label }}
      </router-link>
    </menu>
    <section>
      <ProductCatalogOrganism :search="search" :type="selectedProductType" />
    </section>
  </ProductCatalogPage>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import ProductCatalogOrganism from './ProductCatalogOrganism'

const ProductCatalogPage = styled('main')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  background: ${({ theme }) => chroma(theme.colors.solidBG).alpha(0.7).css()};
  overflow: hidden;
  > menu {
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    background: ${({ theme }) => theme.colors.solidBG};

    > a {
      display: flex;
      justify-content: center;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid ${({ theme }) => (theme.isDark ? theme.colors.darkGrey : theme.colors.lightGrey)};
      &.router-link-exact-active,
      &.is-forced-active {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  > section {
    margin: 4px 0;
    padding: calc(1rem - 4px) 1rem;

    overflow: auto;
  }
`

export default {
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  components: {
    ProductCatalogPage,
    ProductCatalogOrganism,
  },
  computed: {
    selectedProductType() {
      return this.$route?.query?.type ?? 'all'
    },
    productTypes() {
      return ['all', 'app', 'bundle'].map(productType => {
        return {
          id: productType,
          label: this.$t(`marketplace.productTypes.${productType}`),
          to: {
            name: 'ProductCatalog',
            query: {
              ...this.$route.query,
              type: productType === 'all' ? undefined : productType,
            },
          },
          isForcedActive: productType === 'all' && this.$route?.query?.type === undefined,
        }
      })
    },
  },
}
</script>
